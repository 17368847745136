import { ExcalidrawExtensionService } from "@smartsuite/smartdoc/lib/esm/extensions/exacalidraw/service";

export class SSExcalidrawExtensionService implements ExcalidrawExtensionService {
  protocol = "ss://";

  validateRecordEmbedLink(url: string): boolean {
    return url.toLowerCase().startsWith(this.protocol);
  }

  getFieldLinkSlug(url: string): string {
    return url.toLowerCase().replace(this.protocol, "");
  }

  generateFieldEmbedLink(field: string): string {
    return `${this.protocol}${field}`;
  }

  isValidAppField(slug: string): boolean {
    return false; // TODO: implement if we need field embeds to work on mobile view
  }

  getRecordValue(slug: string): string {
    return ""; // TODO: implement if we need field embeds to work on mobile view
  }

  getRecordFields(): Record<string, string> {
    return {};
  }

  isEditable(): boolean {
    return false;
  }
}
