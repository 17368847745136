import { Colors } from "@smartsuite/react-ui";

export const colorNames = {
  skyBlue: `Sky Blue`,
  articBlue: `Arctic Blue`,
  forestGreen: `Forest Green`,
  cherryRed: `Cherry Red`,
  orange: `Orange`,
  canaryYellow: `Canary Yellow`,
  violet: `Violet`,
  sunrisePink: `Sunrise Pink`,
  turquoise: `Turquoise`,
  steelGrey: `Steel Grey`,
};

export const baseColors = [
  {
    color: Colors.bBlue,
    name: `75% ${colorNames.skyBlue}`,
  },
  {
    color: Colors.bLightBlue,
    name: `75% ${colorNames.articBlue}`,
  },
  {
    color: Colors.bGreen,
    name: `75% ${colorNames.forestGreen}`,
  },
  {
    color: Colors.bRed,
    name: `75% ${colorNames.cherryRed}`,
  },
  {
    color: Colors.bOrange,
    name: `75% ${colorNames.orange}`,
  },
  {
    color: Colors.bYellow,
    name: `75% ${colorNames.canaryYellow}`,
  },
  {
    color: Colors.bPurple,
    name: `75% ${colorNames.violet}`,
  },
  {
    color: Colors.bPink,
    name: `75% ${colorNames.sunrisePink}`,
  },
  {
    color: Colors.bTeal,
    name: `75% ${colorNames.turquoise}`,
  },
  {
    color: Colors.bGrey,
    name: `75% ${colorNames.steelGrey}`,
  },
];
