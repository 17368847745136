import { WebsocketProvider } from "y-websocket";
import * as YJS from "yjs";
import { Doc, EditorSchema, isDocEmpty } from "@smartsuite/smartdoc";
import { useEffect, useMemo, useState } from "react";
import { prosemirrorJSONToYDoc, yDocToProsemirrorJSON } from "y-prosemirror";
import { toByteArray } from "base64-js";
import { baseColors } from "./colors";

export type YjsConnectionStatus = "connected" | "disconnected" | "connecting";

export interface UseYjsProviderResult {
  yDoc: YJS.Doc;
  yjsProvider: WebsocketProvider;
  connectionStatus: YjsConnectionStatus;
  receivedFirstUpdate: boolean;
  wasEverConnected: boolean;
}

export interface UseYjsProviderProps {
  enabled: boolean;
  connect: boolean;
  accountSlug: string;
  applicationId: string;
  recordId: string;
  fieldSlug: string;
  initialDoc: Doc;
  initialYjsData: string | undefined;
  getSchema: () => EditorSchema;
  memberName: string;
  env: string;
  accessToken: string;
  webSocketURL: string;
}

export function useYjsProvider({
  enabled,
  connect,
  accountSlug,
  applicationId,
  recordId,
  fieldSlug,
  initialDoc,
  initialYjsData,
  getSchema,
  memberName,
  env,
  accessToken,
  webSocketURL,
}: UseYjsProviderProps): UseYjsProviderResult {
  const [yjsProvider, yDoc] = useMemo(
    () =>
      createYJSProvider(
        initialYjsData,
        accountSlug,
        applicationId,
        recordId,
        fieldSlug,
        memberName,
        env,
        accessToken,
        webSocketURL
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const [connectionStatus, setConnectionStatus] = useState<YjsConnectionStatus>(
    !connect ? "disconnected" : "connecting"
  );
  const [receivedFirstUpdate, setReceivedFirstUpdate] = useState<boolean>(
    !!initialYjsData || isDocEmpty(initialDoc)
  );
  const [wasEverConnected, setWasEverConnected] = useState<boolean>(false);

  useEffect(() => {
    if (!enabled || !connect) {
      populateValue(initialDoc, initialYjsData, yDoc, getSchema());
      return;
    }

    if (initialYjsData) {
      // collab is enabled, populate with `yjsData` if it exists
      YJS.applyUpdate(yDoc, toByteArray(initialYjsData));
    } else if (isDocEmpty(yDocToProsemirrorJSON(yDoc))) {
      // in case `yjsData` doesn't exist, the yjs-server will emit the initial value later on after the connection is established
    }

    let connectAttempt = 1;
    let hasEverConnected = false;

    yDoc.on("update", (): void => {
      setReceivedFirstUpdate(true);
    });

    yjsProvider.on("status", (event: { status: YjsConnectionStatus }) => {
      setConnectionStatus(event.status);

      if (event.status === "connected") {
        hasEverConnected = true;
        setWasEverConnected(true);
        connectAttempt = 1;
      }
    });

    yjsProvider.on("connection-error", (): void => {
      if (hasEverConnected) {
        // in case of a connection error after we were connected before,
        // we should try to reconnect when the user is back online if the initial field value contains a collaborative state
        setConnectionStatus("disconnected");
        yjsProvider.shouldConnect = !!initialYjsData;
        return;
      }

      if (connectAttempt < 3) {
        // if never connected before, try to reconnect 3 times
        connectAttempt++;
      } else {
        // otherwise, populate yDoc with local data and don't reconnect unless the initial value
        // contains a collaborative state, in this case we assume its safe to reconnect
        populateValue(initialDoc, initialYjsData, yDoc, getSchema());
        setConnectionStatus("disconnected");
        yjsProvider.shouldConnect = !!initialYjsData;
      }
    });

    if (connect) {
      yjsProvider.connect();
    }

    return () => {
      yjsProvider?.disconnect();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, connect, yjsProvider]);

  return {
    yDoc,
    yjsProvider,
    connectionStatus,
    receivedFirstUpdate,
    wasEverConnected,
  };
}

/** Populate a collaborative document yDoc
 * with the value from yjsData or from the doc itself depending on the yjsData being available or not. */
function populateValue(
  doc: Doc,
  yjsData: string | undefined,
  yDoc: YJS.Doc,
  schema: EditorSchema
): void {
  // eslint-disable-next-line no-extra-boolean-cast
  if (!!yjsData) {
    YJS.applyUpdate(yDoc, toByteArray(yjsData));
  } else if (!isDocEmpty(doc) && isDocEmpty(yDocToProsemirrorJSON(yDoc))) {
    const offlineDoc = prosemirrorJSONToYDoc(schema, doc);
    YJS.applyUpdate(yDoc, YJS.encodeStateAsUpdate(offlineDoc));
  }
}

const defaultWebSocketURL = "wss://smartdoc.app.smartsuite.com";

function createYJSProvider(
  initialYjsData: string | undefined,
  accountSlug: string,
  applicationId: string,
  recordId: string,
  fieldSlug: string,
  memberName: string,
  env: string,
  accessToken: string,
  webSocketURL: string
): [WebsocketProvider, YJS.Doc] {
  const yDoc = new YJS.Doc();
  const yjsProvider = new WebsocketProvider(
    webSocketURL || defaultWebSocketURL,
    `${recordId}/${fieldSlug}`,
    yDoc,
    {
      params: {
        accessToken,
        initialize: Boolean(!initialYjsData).toString(),
        account: accountSlug,
        application: `${applicationId}`,
        record: `${recordId}`,
        field: fieldSlug,
        env,
        mode: "record",
      },
      connect: false,
    }
  );

  yjsProvider.awareness.setLocalStateField("user", {
    name: memberName,
    color: baseColors[Math.floor(Math.random() * baseColors.length)],
  });

  return [yjsProvider, yDoc];
}
