/* eslint-disable @typescript-eslint/no-explicit-any */
import { FilestackExtensionService as IFilestackExtensionService } from "@smartsuite/smartdoc/lib/esm/extensions/filestack";
import { EditorView } from "@smartsuite/smartdoc/lib/esm/core/types";
import {
  AttachmentNodeAttrs,
  ImageNodeAttrs,
  VideoNodeAttrs,
  UploadMode,
} from "@smartsuite/smartdoc/lib/esm/extensions/filestack/types";
import { Client, Security } from "filestack-js";

import { Platform } from "../../App";

import { getVideoURL } from "./video";

class FilestackExtensionService implements IFilestackExtensionService {
  constructor(
    private readonly client: Client,
    private readonly security: Security,
    private readonly platform: Platform
  ) {}

  getAttachmentUrl(attrs: AttachmentNodeAttrs): string {
    return attrs?.file?.handle
      ? this.client.transform(attrs.file.handle, { security: this.security })
      : "";
  }

  getImageUrl(attrs: ImageNodeAttrs): string {
    return attrs?.file?.handle
      ? this.client.transform(attrs.file.handle, {
          security: this.security,
          resize: { width: attrs.size.width },
        })
      : "";
  }

  getVideoUrl(attrs: VideoNodeAttrs): string {
    const videoURL = getVideoURL(attrs, this.client, this.security, this.platform);

    return videoURL;
  }

  validateUploadingFile(file: File): boolean {
    if (file.size > 1 * 1024 * 1024) {
      window.alert("Maximum size is 1MB");
      return false;
    }

    return true;
  }

  async uploadByUrl(url: string): Promise<any> {}

  async uploadFile(file: File): Promise<any> {}

  async openPicker(mode: UploadMode): Promise<any> {}

  openModalGallery(view: EditorView, attrs: ImageNodeAttrs): void {}
}

export { FilestackExtensionService };
