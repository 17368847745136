import {
  MentionApplication,
  MentionTarget,
  MentionExtensionService,
  MentionAttrs,
} from "@smartsuite/smartdoc/lib/cjs/extensions/mention/types";

export const app1: MentionApplication = {
  id: "app-1",
  name: "Application 1",
  slug: "app-1",
};
export const app2: MentionApplication = {
  id: "app-2",
  name: "Application 2",
  slug: "app-2",
};

export const records: MentionTarget[] = [
  { id: "record-1", title: "Record 1", application: app1 },
  { id: "record-2", title: "Record 2", application: app1 },
  { id: "record-3", title: "Record 3", application: app2 },
];

export class MentionExtension implements MentionExtensionService {
  users;
  teams;

  constructor(members: MentionTarget[], teams: MentionTarget[]) {
    this.users = members;
    this.teams = teams;
  }

  isCurrentMember(attrs: MentionAttrs): boolean {
    return false;
  }

  openDetails(attrs: MentionAttrs, target: HTMLElement): void {}

  openPublicProfile(memberId: string): void {}

  searchMembers(query: string): MentionTarget[] {
    return this.users.filter((el) => el.title.startsWith(query));
  }

  searchTeams(query: string): MentionTarget[] {
    return this.teams.filter((el) => el.title.startsWith(query));
  }

  searchRecords(query: string): MentionTarget[] {
    return records;
  }
}
