import { FilestackExtensionService, PortalContainer } from "@smartsuite/smartdoc";
import { WhiteboardExtensionService } from "@smartsuite/smartdoc/lib/esm/extensions/whiteboard/service";
import { WhiteboardData } from "@smartsuite/smartdoc/lib/esm/extensions/whiteboard/types";
import { getWhiteboard } from "./whiteboard-api";

export class SSWhiteboardExtensionService implements WhiteboardExtensionService {
  protocol = "ss://";
  env: string;
  accessToken: string;
  accountSlug: string;

  constructor(env: string, accessToken: string, accountSlug: string) {
    this.env = env;
    this.accessToken = accessToken;
    this.accountSlug = accountSlug;
  }

  isEditable(): boolean {
    return false;
  }

  validateRecordEmbedLink(url: string): boolean {
    return url.toLowerCase().startsWith(this.protocol);
  }

  getFieldLinkSlug(url: string): string {
    return url.toLowerCase().replace(this.protocol, "");
  }

  generateFieldEmbedLink(field: string): string {
    return `${this.protocol}${field}`;
  }

  isValidAppField(slug: string): boolean {
    // not needed in mobile
    return true;
  }

  getRecordValue(slug: string): string {
    // not needed in mobile
    return "";
  }

  getRecordFields(): Record<string, string> {
    // not needed in mobile
    return {};
  }

  withPortalContainer(portalContainer: PortalContainer): WhiteboardExtensionService {
    // not needed in mobile
    return this;
  }

  withFilestackService(filestackService: FilestackExtensionService): WhiteboardExtensionService {
    // not needed in mobile
    return this;
  }

  async fetchWhiteboard(id: string): Promise<WhiteboardData> {
    const whiteboard = await getWhiteboard(this.env, this.accessToken, this.accountSlug, id);
    return {
      ...whiteboard.data,
      updatedAt: whiteboard.updated_on
        ? new Date(whiteboard.updated_on).getTime().toString()
        : null,
    };
  }

  async createWhiteboard(initialData?: WhiteboardData): Promise<string> {
    // not needed in mobile
    return await Promise.resolve("");
  }

  async updateWhiteboard(whiteboardId: string, data: WhiteboardData): Promise<void> {
    // not needed in mobile
  }

  openWhiteboard(whiteboardId: string, onClose?: (data: WhiteboardData) => void): void {
    // not needed in mobile
  }
}
