import {
  createFullPresetExtensions,
  Extension,
  FormattingToolbarExtensionOptions,
  BaseSchemaExtension,
  ListExtension,
  TrailingParagraphExtension,
  BoldExtension,
  ItalicExtension,
  UnderlineExtension,
  StrikethroughExtension,
  UrlExtension,
  PlaceholderExtension,
  PopupExtension,
  BasePluginsExtension,
  FormattingToolbarExtension,
  BlockquoteExtension,
  CodeBlockExtension,
  EmojiExtension,
  HorizontalRuleExtension,
  CodeExtension,
  TabsExtension,
  MentionExtension,
  FilestackExtension,
  CollabExtension,
  HighlightExtension,
  ColorExtension,
  MaxLengthExtension,
  SmallParagraphExtension,
} from "@smartsuite/smartdoc";
import { MentionTarget } from "@smartsuite/smartdoc/lib/cjs/extensions/mention/types";
import { WebsocketProvider } from "y-websocket";
import { Client, Security } from "filestack-js";

import { SSExcalidrawExtensionService } from "./excalidraw/excalidraw.service";
import { FilestackExtensionService } from "./filestack/filestack";
import { MentionExtension as MentionExtensionService } from "./mention/mention";
import { SSWhiteboardExtensionService } from "./whiteboard/whiteboard-extension.service";

import { Platform } from "../App";

type BasePreset = {
  filestackClient: Client;
  security: Security;
  users: MentionTarget[];
  teams: MentionTarget[];
  yjsProvider: WebsocketProvider;
  platform: Platform;
};

type FullPreset = BasePreset & {
  editable: boolean;
  env: string | null;
  accessToken: string | null;
  accountSlug: string | null;
  tip: string;
  placeholder: string;
};

export const fullPreset = ({
  editable,
  filestackClient,
  security,
  users,
  teams,
  yjsProvider,
  env,
  accessToken,
  accountSlug,
  tip,
  placeholder,
  platform,
}: FullPreset): Extension[] => {
  const toolbar: FormattingToolbarExtensionOptions | undefined = editable
    ? {
        mode: "static",
        disableTooltips: true,
      }
    : undefined;

  return createFullPresetExtensions({
    filestackService: new FilestackExtensionService(filestackClient, security, platform),
    mentionService: new MentionExtensionService(users, teams),
    collab: {
      getProvider: () => yjsProvider,
    },
    formattingToolbar: toolbar,
    placeholder: {
      tip: tip || "Tip: Enter “/” for commands", // NOTE: The backward compatibility
      placeholder: placeholder || "Write here", // NOTE: The backward compatibility
      placeholderMode: "empty-doc",
    },
    excalidrawService: new SSExcalidrawExtensionService(),
    whiteboardService: new SSWhiteboardExtensionService(
      env ?? "local",
      accessToken ?? "",
      accountSlug ?? ""
    ),
  });
};

type CommentsPreset = BasePreset & {
  tip: string;
  placeholder: string;
};

// SOURCE: https://gitlab.com/smartsuite/main-app/frontend/-/blob/main/frontend/projects/main/src/app/editor/extensions/extensions.service.ts#L159
export const commentsPreset = ({
  filestackClient,
  security,
  users,
  teams,
  yjsProvider,
  tip,
  placeholder,
  platform,
}: CommentsPreset): Extension[] => {
  return [
    new BaseSchemaExtension({
      enableParagraphAlignment: false,
    }),
    new BoldExtension(),
    new ItalicExtension(),
    new UnderlineExtension(),
    new StrikethroughExtension(),
    new CodeExtension(),
    new UrlExtension(),
    new ListExtension(),
    new BlockquoteExtension(),
    new CodeBlockExtension(),
    new PlaceholderExtension({
      tip,
      placeholder,
      placeholderMode: "empty-doc",
    }),
    new EmojiExtension(),
    new MentionExtension(new MentionExtensionService(users, teams)),
    new FilestackExtension(new FilestackExtensionService(filestackClient, security, platform)),
    new HorizontalRuleExtension(),
    new PopupExtension(),
    new SmallParagraphExtension(),
    new ColorExtension(),
    new HighlightExtension(),
    new TrailingParagraphExtension(),
    new BasePluginsExtension(),
    new TabsExtension(),
    new FormattingToolbarExtension({ mode: "static", disableTooltips: true }),
    new CollabExtension({
      // NOTE: The Comments don't have the collab functionality
      // but that extension is required for editing comment
      getProvider: () => yjsProvider,
    }),
  ];
};

type SimplePreset = {
  yjsProvider: WebsocketProvider;
  tip: string;
  placeholder: string;
};

// SOURCE: https://gitlab.com/smartsuite/main-app/frontend/-/blob/main/frontend/projects/main/src/app/solution/field-form/field-form.helpers.ts#L101
export const simplePreset = ({ yjsProvider, tip, placeholder }: SimplePreset): Extension[] => {
  return [
    new BaseSchemaExtension({
      enableParagraphAlignment: false,
    }),
    new HighlightExtension(),
    new ColorExtension(),
    new ListExtension(),
    new SmallParagraphExtension(),
    new TrailingParagraphExtension(),
    new BoldExtension(),
    new ItalicExtension(),
    new UnderlineExtension(),
    new StrikethroughExtension(),
    new UrlExtension(),
    new PlaceholderExtension({
      tip,
      placeholder,
      placeholderMode: "empty-doc",
    }),
    new MaxLengthExtension(700),
    new PopupExtension(),
    new BasePluginsExtension(),
    new FormattingToolbarExtension({ mode: "static", disableTooltips: true }),
    new CollabExtension({
      // NOTE: The Simple don't have the collab functionality
      // but that extension is required for editing comment
      getProvider: () => yjsProvider,
    }),
  ];
};

export type Preset = "full" | "comments" | "simple";
