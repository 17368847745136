import { Doc } from "@smartsuite/smartdoc";

export interface EditorMessage {
  type: string;
  doc?: Doc;
  yjsData?: string | null;

  [key: string]: string | number | null | Doc | undefined;
}

/**
 * Checks if we are rendering in a `react-native-webview` context.
 * We do that by checking if there's a `window.ReactNativeWebView.postMessage` function
 */
export function isReactNativeWebViewContext(): boolean {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (window as any).ReactNativeWebView && (window as any).ReactNativeWebView.postMessage;
}

/**
 * Post a typed editor message using `react-native-webview` function `window.ReactNativeWebView.postMessage`.
 * The message must be a raw string, so we first convert our message object to a JSON string using `JSON.stringify`.
 * The message must be parsed back by the `react-native-webview` client.
 */
export function postMessage(message: EditorMessage): void {
  postRawMessage(JSON.stringify(message));
}

/**
 * Post a raw string message using `react-native-webview` function `window.ReactNativeWebView.postMessage`
 */
function postRawMessage(message: string): void {
  if (isReactNativeWebViewContext()) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).ReactNativeWebView.postMessage(message);
  }
}
