/**
 * Service for Whiteboard backend integration
 */
import { WhiteboardData } from "@smartsuite/smartdoc/lib/esm/extensions/whiteboard/types";

export interface Whiteboard {
  id: string;
  account_slug: string;
  record: string;
  application: string;
  data: WhiteboardData;
  updated_on?: string;
  created_on?: string;
}

const baseUrl = "/api/v1/whiteboards/";

const envUrlMap: Record<string, string> = {
  local: "http://localhost:8000",
  development: "https://dev.smartsuite.com",
  staging: "https://staging.smartsuite.com",
  hotfix: "https://hotfix.smartsuite.com",
  production: "https://app.smartsuite.com",
};

function getBaseUrl(env: string): string {
  return `${envUrlMap[env] ?? envUrlMap.production}${baseUrl}`;
}

export async function getWhiteboard(
  env: string,
  accessToken: string,
  accountId: string,
  id: string
): Promise<Whiteboard> {
  const response = await fetch(`${getBaseUrl(env)}${id}/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "ACCOUNT-ID": accountId,
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return (await response.json()) as Whiteboard;
}
