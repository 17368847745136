import { Client, Security } from "filestack-js";
import { VideoNodeAttrs } from "@smartsuite/smartdoc/lib/esm/extensions/filestack/types";

import { Platform } from "../../App";

const UNSUPPORTED_VIDEO_FORMATS = [
  "video/avchd",
  "video/mp2t",
  "video/avi",
  "video/x-msvideo",
  "video/x-flv",
  "video/mpeg",
  "video/mpg",
  "video/ogg",
  "audio/ogg",
  "video/x-ms-wmv",
];

const ANDROID_UNSUPPORTED_VIDEO_FORMATS = [...UNSUPPORTED_VIDEO_FORMATS];

const IOS_UNSUPPORTED_VIDEO_FORMATS = [...UNSUPPORTED_VIDEO_FORMATS, "video/webm"];

const checkIsSupportedVideoFormat = (attrs: VideoNodeAttrs, platform: Platform): boolean => {
  try {
    const mimetype = attrs?.file?.metadata?.mimetype;

    console.log("platform: ", platform);

    if (platform === "android") {
      if (ANDROID_UNSUPPORTED_VIDEO_FORMATS.includes(mimetype)) {
        return false;
      }
    }

    if (platform === "ios") {
      if (IOS_UNSUPPORTED_VIDEO_FORMATS.includes(mimetype)) {
        return false;
      }
    }

    return true;
  } catch (error) {
    return true;
  }
};

// HACK: https://muffinman.io/blog/hack-for-ios-safari-to-display-html-video-thumbnail/
const HACK_VIDEO_THUMBNAIL = "#t=0.001";

const getVideoURL = (
  attrs: VideoNodeAttrs,
  client: Client,
  security: Security,
  platform: Platform
): string => {
  const isSupportedVideoFormat = checkIsSupportedVideoFormat(attrs, platform);

  const filestackURL = attrs?.file?.handle ? client.transform(attrs.file.handle, { security }) : "";
  const isAndroid = platform === "android";
  const platformName = isAndroid ? "android" : "ios";
  // HACK: This link will be passed into library using patch-package and query params
  const unsupportedVideoThumbnailURL = `${process.env.PUBLIC_URL}/unsupported-video-thumbnail-${platformName}.png`;

  const videoURL = `${filestackURL}${
    isSupportedVideoFormat ? HACK_VIDEO_THUMBNAIL : `?poster=${unsupportedVideoThumbnailURL}`
  }`;

  return videoURL;
};

export { getVideoURL };
