import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import classnames from "classnames";

import { Doc, EditorSchema, isDocEmpty, Editor } from "@smartsuite/smartdoc";
import { MentionTarget } from "@smartsuite/smartdoc/lib/esm/extensions/mention/types";
import { init, Security } from "filestack-js";

import "./styles.sass";
import { EditorView } from "@smartsuite/smartdoc/lib/esm/core/types";
import { useYjsProvider } from "./extensions/collab/useYjsProvider";
import { yDocToProsemirrorJSON } from "y-prosemirror";
import { fromByteArray } from "base64-js";
import * as YJS from "yjs";
import { postMessage } from "./helpers/events";
import { commentsPreset, fullPreset, Preset, simplePreset } from "./extensions";

export type Platform = "android" | "ios" | null;

export const App: React.FC = () => {
  const [initialDoc, setInitialDoc] = useState<Doc>();
  const [initialYjsData, setInitialYjsData] = useState<string>();
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const [security, setSecurity] = useState<Security>();
  const [ready, setReady] = useState(false);
  const [editable, setEditable] = useState(true);
  const [accountSlug, setAccountSlug] = useState<string | null>(null);
  const [applicationId, setApplicationId] = useState<string | null>(null);
  const [recordId, setRecordId] = useState<string | null>(null);
  const [fieldSlug, setFieldSlug] = useState<string | null>(null);
  const [memberName, setMemberName] = useState<string | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [env, setEnv] = useState<string | null>(null);
  const [webSocketURL, setWebSocketURL] = useState<string | null>(null);
  const [filestackAPIKey, setFilestackAPIKey] = useState<string | null>(null);
  const [platform, setPlatform] = useState<Platform>(null);
  const [preset, setPreset] = useState<Preset>("full");
  const [tip, setTip] = useState("");
  const [placeholder, setPlaceholder] = useState("");

  useEffect(() => {
    const listener = (e: { data: string }): void => {
      if (typeof e.data !== "string") {
        return;
      }
      const doc = JSON.parse(e.data);
      if (typeof doc === "object") {
        if (doc.isDarkTheme) {
          document.body.classList.add("dark-mode");

          if (
            doc.darkModeCustomBackgroundColor &&
            typeof doc.darkModeCustomBackgroundColor === "string"
          ) {
            document.body.style.setProperty(
              "--dark-mode-custom-background-color",
              doc.darkModeCustomBackgroundColor
            );
            document.body.classList.add("custom-background", "dark-mode");
          }
        } else {
          document.body.classList.add("light-mode");

          if (
            doc.lightModeCustomBackgroundColor &&
            typeof doc.lightModeCustomBackgroundColor === "string"
          ) {
            document.body.style.setProperty(
              "--light-mode-custom-background-color",
              doc.lightModeCustomBackgroundColor
            );
            document.body.classList.add("custom-background", "light-mode");
          }
        }
        if (doc.isReadOnly) {
          document.body.classList.add("read-only");
        } else {
          document.body.classList.add("edit-mode");
        }
        if (doc.disableHeadingExpandButton) {
          document.body.classList.add("disable-heading-expand-button");
        }
        if (doc.ignoreHorizontalPadding) {
          document.body.classList.add("no-padding");
        }
        setInitialDoc(doc.initValue);
        setInitialYjsData(doc?.initYjsData);
        setSecurity(doc.security);
        setUsers(doc.users);
        setTeams(doc.teams);
        setIsDarkTheme(doc.isDarkTheme);
        setEditable(doc.editable);
        if (doc.accountSlug) {
          setAccountSlug(doc.accountSlug);
        }
        if (doc.applicationId) {
          setApplicationId(doc.applicationId);
        }
        if (doc.recordId) {
          setRecordId(doc.recordId);
        }
        if (doc.fieldSlug) {
          setFieldSlug(doc.fieldSlug);
        }
        if (doc.memberName) {
          setMemberName(doc.memberName);
        }
        if (doc.accessToken) {
          setAccessToken(doc.accessToken);
        }
        if (doc.env) {
          setEnv(doc.env);
        }
        if (doc.webSocketURL) {
          setWebSocketURL(doc.webSocketURL);
        }
        if (doc.filestackAPIKey) {
          setFilestackAPIKey(doc.filestackAPIKey);
        }
        if (doc.platform) {
          setPlatform(doc.platform);

          if (doc.platform === "ios" && !!doc.editable) {
            // NOTE: this hack breaks AutoHeightWebView so we
            // disabled it when the toolbar is not available
            document.body.classList.add("fix-ios-sticky-header");
            document.documentElement.classList.add("fix-ios-sticky-header"); // NOTE: html tag
          }
        }
        if (doc.preset) {
          setPreset(doc.preset);
        }
        if (doc.tip) {
          setTip(doc.tip);
        }
        if (doc.placeholder) {
          setPlaceholder(doc.placeholder);
        }
        setReady(true);
      }
    };
    // ios
    window.addEventListener("message", listener);
    // android
    document.addEventListener("message", listener as never);

    return () => {
      window.removeEventListener("message", listener);
      document.removeEventListener("message", listener as never);
    };
  }, []);

  if (!ready) {
    return null;
  }

  return (
    <SmartdocWidget
      editable={editable}
      initialDoc={initialDoc}
      initialYjsData={initialYjsData}
      isDarkTheme={isDarkTheme}
      security={security ?? { policy: "", signature: "" }}
      users={users}
      teams={teams}
      applicationId={applicationId}
      recordId={recordId}
      fieldSlug={fieldSlug}
      memberName={memberName}
      accountSlug={accountSlug}
      accessToken={accessToken}
      env={env}
      webSocketURL={webSocketURL}
      filestackAPIKey={filestackAPIKey}
      platform={platform}
      preset={preset}
      tip={tip}
      placeholder={placeholder}
    />
  );
};

interface SmartdocWidgetProps {
  isDarkTheme: boolean;
  security: Security;
  users: MentionTarget[];
  teams: MentionTarget[];
  initialDoc: Doc | undefined;
  initialYjsData?: string;
  editable?: boolean;
  applicationId: string | null;
  recordId: string | null;
  fieldSlug: string | null;
  memberName: string | null;
  accountSlug: string | null;
  accessToken: string | null;
  env: string | null;
  webSocketURL: string | null;
  filestackAPIKey: string | null;
  platform: Platform;
  preset: Preset;
  tip: string;
  placeholder: string;
}

const SmartdocWidget: React.FC<SmartdocWidgetProps> = ({
  security,
  users,
  teams,
  initialDoc,
  initialYjsData,
  editable = true,
  accountSlug,
  applicationId,
  recordId,
  fieldSlug,
  memberName,
  accessToken,
  env,
  webSocketURL,
  filestackAPIKey,
  platform,
  preset,
  tip,
  placeholder,
}) => {
  const enableCollab =
    !!editable &&
    !!accountSlug &&
    !!applicationId &&
    !!fieldSlug &&
    !!memberName &&
    !!accessToken &&
    !!env &&
    !!webSocketURL;
  const yjsConnect = enableCollab && !!recordId;

  const viewRef = useRef<EditorView>();

  const { yjsProvider, yDoc } = useYjsProvider({
    enabled: enableCollab,
    connect: yjsConnect,
    applicationId: applicationId as string,
    recordId: recordId as string,
    fieldSlug: fieldSlug as string,
    initialDoc: initialDoc as Doc,
    webSocketURL: webSocketURL as string,
    accountSlug: accountSlug as string,
    memberName: memberName as string,
    accessToken: accessToken as string,
    env: env as string,
    initialYjsData,
    getSchema: useCallback(() => viewRef.current?.state.schema as EditorSchema, []),
  });

  const filestackClient = useMemo(() => {
    // NOTE: "process.env.REACT_APP_FILESTACK_API_KEY" is backward compatibility for v1.41
    return init(filestackAPIKey ?? (process.env.REACT_APP_FILESTACK_API_KEY as string));
  }, [filestackAPIKey]);

  const extensions = useMemo(() => {
    if (preset === "comments") {
      return commentsPreset({
        filestackClient,
        security,
        users,
        teams,
        yjsProvider,
        tip,
        placeholder,
        platform,
      });
    }

    if (preset === "simple") {
      return simplePreset({
        yjsProvider,
        tip,
        placeholder,
      });
    }

    return fullPreset({
      editable,
      filestackClient,
      security,
      users,
      teams,
      yjsProvider,
      env,
      accessToken,
      accountSlug,
      tip,
      placeholder,
      platform,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const registerViewportResizeListener = useCallback((): void => {
    // This listener will trigger everytime the virtual keyboard is opened/closed
    // we use it to reposition the sticky toolbar in the editor
    if (window.visualViewport) {
      const handleResize = (): void => {
        const toolbar = document.querySelector(".rn-editor .editor-toolbar");
        if (toolbar) {
          // const toolbarHeight = toolbar.getBoundingClientRect().height;
          const toolbarWrapper = toolbar.parentElement;

          if (toolbarWrapper) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            toolbarWrapper.style.top = `${window.visualViewport!.pageTop}px`;
          }
        }
      };

      window.visualViewport.addEventListener("resize", handleResize);
    }
  }, []);

  const handleViewCreated = useCallback(
    (view: EditorView): void => {
      viewRef.current = view;
      if (platform === "ios") {
        registerViewportResizeListener();
      }
    },
    [platform, registerViewportResizeListener]
  );

  const handleContentChange = useCallback(
    (doc: Doc): void => {
      const yjsData = !isDocEmpty(yDocToProsemirrorJSON(yDoc) as Partial<Doc>)
        ? fromByteArray(YJS.encodeStateAsUpdate(yDoc))
        : null;
      postMessage({ type: "change", doc, yjsData });
    },
    [yDoc]
  );

  return (
    <div className={classnames("rn-editor", platform)}>
      <Editor
        onViewCreated={handleViewCreated}
        editable={editable}
        extensions={extensions}
        onContentChange={handleContentChange}
      />
    </div>
  );
};
